<template>
	<div class="active-order el-content">
		<a-tabs v-model:activeKey="status" @change="getOrderList(1,info.limit)">
			<a-tab-pane key="0" tab="全部"></a-tab-pane>
			<a-tab-pane key="1" tab="待审核"></a-tab-pane>
			<a-tab-pane key="2" tab="待验票"></a-tab-pane>
			<a-tab-pane key="4" tab="已检票"></a-tab-pane>
		</a-tabs>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'订单编号',dataIndex:'order_number',slots:{customRender:'order_number'}},
			{title:'姓名',dataIndex:'otherdata',slots:{customRender:'otherdata'}},
			{title:'电话',dataIndex:'phone'},
			{title:'状态',dataIndex:'is_settle',slots:{customRender:'is_settle'}},
			{title:'支付方式',dataIndex:'pay_method',slots:{customRender:'pay_method'}},
			{title:'下单时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #order_number="{record}">
				<a-tooltip>
					<template #title>
						<p>购票信息：</p>
						<p v-for="(v,i) in record.order_details" :key="i">{{v.data.title}} * {{v.num}}张</p>
					</template>
					<span>{{record.order_number}}</span>
				</a-tooltip>
			</template>
			<template #otherdata="{record}">
				<a-tooltip>
					<template #title>
						<p v-for="(v,i) in record.otherdata" :key="i">{{v.name}} : {{v.value}}</p>
					</template>
					<span>{{record.name}}</span>
				</a-tooltip>
			</template>
			<template #is_settle="{record}">
				<a-tag :color="getStatus(record,2)">{{getStatus(record,1)}}</a-tag>
			</template>
			<template #pay_method="{record}">
				<a-tag color="#0099FF">{{$util.payMethod(record.pay_method)}}</a-tag>
			</template>
			<template #action="{record}">
				<!-- 需要审核 -->
				<a-space v-if="record.is_check == 1 && record.is_refund == 1 && record.pay_status == 1">
					<kd-button 
						type="primary" 
						title="通过" 
						icon="ri-check-double-line" 
						v-has="{action:'wxapp_adhibition_active_order_check'}"  
						@click="passCheck(record.id)">
					</kd-button>
					<kd-button 	
						type="danger" 
						title="驳回" 
						icon="ri-close-line" 
						v-has="{action:'wxapp_adhibition_active_order_check'}"  
						@click="rejectCheck(record.id)">
					</kd-button>
				</a-space>
				<template v-if="(record.is_check == 2 || record.is_check == 0) && record.status==1">
					<kd-button 
						type="success" 
						title="驳回" 
						icon="ri-body-scan-line" 
						v-has="{action:'wxapp_adhibition_active_check'}"  
						@click="checkTicket(record.id,record.rid)">
					</kd-button>
				</template>
				
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
				@change="(e)=>{getOrderList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.reject" title="驳回申请" @ok="sureRejct" width="500px" @cancel="show.reject = false">
			<a-textarea v-model:value="rejectForm.msg" placeholder="驳回理由" :rows="4" />
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import activeModel from '@/api/addons/active'
export default{
	computed:{
		getStatus(){
			return function(s,t){
				if( s.is_check == 1 ){  //需要审核
					if( s.pay_status == 0 ) return t== 1 ? '未支付':'#999'
					if( s.pay_status == 1 && s.is_refund == 2) return t==1 ? '已退款' :'#FF0066'
					if( s.pay_status == 1 && s.status == 1) return t==1 ? '待审核' :'#FF9900'
				}
				
				if( s.is_check == 0 ){  //不需要审核
					if( s.pay_status == 0) return t== 1 ? '未支付':'#999'
					if( s.pay_status == 1 && s.is_refund == 2) return t==1 ? '已退款' :'#FF0066'
					if( s.pay_status == 1 && s.status == 1) return t==1 ? '待验票' :'#999'
					if( s.pay_status == 1 && s.status == 2) return t== 1 ? '已验票':'#00CC66'
					if( s.pay_status == 1) return t== 1 ? '已支付':'#00CC66'
				}
				
				if( s.is_check == 2 ){  //审核通过
					if( s.status == 1 ) return t==1 ? '待验票' :'#999'
					if( s.status == 2 ) return t==1 ? '已验票' :'#00CC66'
				}
				
				if( s.is_check == 3 ){
					return t==1 ? '驳回审核（已退款）' :'#FF0066'
				}
			}
		}
	},
	created() {
		this.getOrderList(1,this.info.limit)
	},

	setup(){
		const state = reactive({
			status:'0',
			info:{
				list:[],
				page:1,
				count:0,
				limit:10					
			},
			show:{ reject:false, },
			rejectForm:{
				order_id:0,
				type:2,
				msg:''
			},
		})
		getOrderList(1,state.info.limit)

		function getOrderList(page,limit){
			activeModel.getActiveOrder(page,limit,{status:state.status},res=>state.info = {limit,...res})
		}

		function rejectCheck(id){
			state.rejectForm.order_id = id
			state.show.reject = true
		}

		const passCheck = id=>activeModel.auditActiveOrder({order_id:id,type:1},()=>{
			getOrderList(state.info.page,state.info.limit)
		})

		const sureRejct = ()=>activeModel.auditActiveOrder(state.rejectForm,()=>{
			state.show.reject = false
			getOrderList(state.info.page,state.info.limit)
		})

		const checkTicket = (order_id,rid)=>activeModel.checkActiveOrder(order_id,rid,()=>{
			getOrderList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getOrderList,
			passCheck,
			sureRejct,
			rejectCheck,
			checkTicket
		}
	}
}
</script>

<style>
</style>
